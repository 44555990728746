/*globals $ */

class OnairInfo {
  constructor(mainApp) {
    this.mainApp          = mainApp;
    this.$container       = $('.OnairInfo');
    this.$scrollContent   = $('.OnairInfoSliderContent');
    this.$text            = $('.OnairInfoSliderContent__text');
    this.isShowInfo       = false;
    this.isActiveSlide    = false;
    this.scrollPos        = 0;
    this.isShowDetail     = false;
  }

  start() {
    this.isShowInfo = true;
    this.onWindowResize();
  }

  update() {
    this.scrollPos += 1;

    this.$scrollContent.css('left', -this.scrollPos);

    if(this.scrollPos >= this.scrollDiff) {
      clearInterval(this.updateInterval);
      this.resetInterval = setInterval(() => {
        this.scrollReset();
        clearInterval(this.resetInterval);
      }, 1000);
    }
  }

  changeViewMode(namespace) {
    if(namespace == 'home') {
      this.isShowDetail = false;
    } else {
      this.isShowDetail = true;
    }
    this.onWindowResize();

    // console.log('changeViewMode', namespace);
  }

  scrollReset() {
    this.scrollPos = 0;
    this.$scrollContent.css('left', -this.scrollPos);

    clearInterval(this.rebootInterval);
    this.rebootInterval = setInterval(() => {
      clearInterval(this.rebootInterval);
      clearInterval(this.updateInterval);
      this.updateInterval = setInterval(() => {
        this.update();
      }, 1000 / 30);

    }, 1000);
  }

  onWindowResize() {
    if(this.isShowInfo) {
      if(this.isShowDetail) {
        this.textWidth = this.$text.width();
        if(this.mainApp.windowWidth < 800) {
          // SP時の場合
          this.offsetLeft = this.$container.offset()['left'];
          this.wrapWidthMax = this.mainApp.windowWidth - this.offsetLeft - 95;
          this.limitWidth = this.wrapWidthMax - 20;
          this.scrollDiff = this.textWidth - this.limitWidth;

          if(this.textWidth > this.limitWidth) {
            this.$container.css('width', this.wrapWidthMax);
            this.isActiveSlide = true;

            clearInterval(this.updateInterval);
            this.updateInterval = setInterval(() => {
              this.update();
            }, 1000 / 30);
          } else {
            this.$container.stop(false).animate({'width': this.textWidth + 20}, 400, 'easeInOutSine');
            clearInterval(this.updateInterval);
            this.isActiveSlide = false;
          }
        } else {
          this.$container.stop(false).animate({'width': this.textWidth + 20}, 400, 'easeInOutSine');
          // this.$container.css('width', this.textWidth + 20);
          clearInterval(this.updateInterval);
          this.isActiveSlide = false;
        }
      } else {
        this.$container.stop(false).animate({'width': 0}, 400, 'easeOutExpo');
      }


    }
  }
}

export default OnairInfo;
