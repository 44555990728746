/*globals $ */

class YouTubeAPI {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.player = this.mainApp.youtubePlayer;
    this.API_KEY = 'AIzaSyCnIpnU7efKmVynJ61_B_G-MiyFjMTKDN8';
    // this.CHANNEL_ID = 'UCSJ4gkVC6NrvII8umztf0Ow'; // ChilledCow
    // this.CHANNEL_ID = 'UCgdHxnHSXvcAi4PaMIY1Ltg'; // SHIBUYA COMMUNITY NEWS

    this.CHANNEL_ID = 'UCnr3mMU99UNilCSC3xkMPJg'; // Rittor Base

    if($('body').attr('data-channel-id') != undefined && String($('body').attr('data-youtube')).length > 1) {
      this.CHANNEL_ID = $('body').attr('data-channel-id');
    }

    this.isOnair = false;
    this.onCompleteCheckStatus = false;


    /*
    https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCXswCcAMb5bvEUIDEzXFGYg&type=video&eventType=live&key=[API_KEY]

    */

    this.onairItems = [];


  }

  start() {
    if($('body').attr('data-onair-id-vimeo') != undefined && String($('body').attr('data-onair-id-vimeo')).length > 1) {
      console.log('get vimeo id');
      this.onairItems = [
        {
          'id': {
            'videoId': $('body').attr('data-onair-id-vimeo')
          }
        }
      ];
      this.isOnair = true;
      this.onCompleteCheckStatus = true;
    } else if($('body').attr('data-onair-id') != undefined && String($('body').attr('data-onair-id')).length > 1) {
      console.log('get youtube id');
      let onairId = $('body').attr('data-onair-id');
      // console.log('onairId', onairId);
      this.onairItems = [
        {
          'id': {
            'videoId': onairId
          }
        }
      ];
      this.isOnair = true;
      this.waitApiInterval = setInterval(() => {
        if(window.onLoadCompleteYoutubeApi) {
          clearInterval(this.waitApiInterval);
          this.onCompleteCheckStatus = true;
        }
      }, 1000 / 30);


    } else {
      let request_url = 'https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=' + this.CHANNEL_ID + '&type=video&eventType=live&key=' + this.API_KEY;
      // console.log('request_url', request_url);

      $.ajax({
        type      : 'GET',
        url       : '/getpage.php?url=' + encodeURIComponent(request_url),
        dataType  : 'json'
      })
      .then(
        (json) => {
          // console.log('ajax success');
          // 放送していない場合はこのlengthが0になる
          // console.log(json);
          // console.log("json['items']", json['items']);
          if(json['items'].length > 0) {
            this.onairItems = json['items'];
            this.isOnair = true;
          }

          this.waitApiInterval = setInterval(() => {
            if(window.onLoadCompleteYoutubeApi) {
              clearInterval(this.waitApiInterval);
              this.onCompleteCheckStatus = true;
            }
          }, 1000 / 30);

        },
        () => {
          // console.log('ajax error');
          this.mainApp.isActiveYoutube = false;
        }
      );

    }


  }
}

export default YouTubeAPI;
