/*globals $ */

class Counter {
  constructor($el) {
    this.$el = $el;

    if(this.$el.attr('data-target-date') != undefined) {
      let d = this.$el.attr('data-target-date');

      if(this.$el.attr('data-target-time') != undefined) {
        let t = this.$el.attr('data-target-time');

        this.targetDate = new Date(
          d.split('-')[0],
          Number(d.split('-')[1]) - 1,
          d.split('-')[2],
          t.split(':')[0],
          t.split(':')[1],
          '0'
        );
      } else {
        this.targetDate = new Date(
          d.split('-')[0],
          Number(d.split('-')[1]) - 1,
          d.split('-')[2],
          '0', '0', '0'
        );
      }
    }

    this.cDay = 0;
    this.cHour = 0;
    this.cMinute = 0;
    this.cSecond = 0;
  }
  update(current) {
    let diff2Dates = this.targetDate.getTime() - current;
    if( this.targetDate.getTime() < current ) {
       // 期限が過ぎた場合は -1 を掛けて正の値に変換
       diff2Dates *= -1;
    }

    this.cDay = Math.floor(diff2Dates / (1000 * 60 * 60 * 24));
    diff2Dates -= (this.cDay * (1000 * 60 * 60 * 24));

    this.cHour = Math.floor(diff2Dates / (1000 * 60 * 60));
    diff2Dates -= (this.cHour * (1000 * 60 * 60));

    this.cMinute = Math.floor(diff2Dates / (1000 * 60));
    diff2Dates -= (this.cMinute * (1000 * 60));

    this.cSecond = Math.floor(diff2Dates / (1000));
    diff2Dates -= (this.cSecond * (1000));

    this.$el.text(
      ('0' + this.cDay).slice(-2)
        + ':' + ('0' + this.cHour).slice(-2)
        + ':' + ('0' + this.cMinute).slice(-2)
        + ':' + ('0' + this.cSecond).slice(-2)
    );
  }
  destroy() {

  }
}

class CounterManager {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.items = [];
  }
  set() {
    clearInterval(this.timerInterval);
    $('.js-counter:not(.counter-initialized)').each((i, e) => {
      this.items.push(new Counter($(e)));
    });
    if(this.items.length > 0) {
      this.update();
      this.timerInterval = setInterval(() => {
        this.update();
      }, 1000);
    }
  }
  update() {
    let nowDate = new Date();

    for(let i = 0; i < this.items.length; i++) {
      this.items[i].update(nowDate.getTime());
    }
  }
  destroy() {
    clearInterval(this.timerInterval);
    if(this.items.length > 0) {
      for(let i = 0; i < this.items.length; i++) {
        this.items[i].destroy();
        this.items[i] = null;
      }
      this.items = [];
    }
  }
}


export default CounterManager;
