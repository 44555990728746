/*globals $ */

class Menu {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.$container = $('.Menu');
    this.$items = [];
    $('.MenuListItem').each((i, e) => {
      this.$items.push($(e));
    });
  }

  open() {
    $('body').addClass('open-menu');
    this.menuMaxNum = 6;
    if(this.mainApp.viewMode == 'pc') {
      this.menuMaxNum = 5;
    }

    for(let i = 0; i < this.menuMaxNum; i++) {
      this.$items[i].stop(false).css({'margin-right':40, 'opacity':0});
    }
    this.$container.css('display', 'flex');

    for(let i = 0; i < this.menuMaxNum; i++) {
      this.$items[this.menuMaxNum - 1 - i]
        .stop(false)
        .delay(i * 30)
        .animate({'opacity':1, 'margin-right':0}, 300, 'easeOutExpo');
    }
  }
  close() {
    $('body').removeClass('open-menu');
    this.menuMaxNum = 6;
    if(this.mainApp.viewMode == 'pc') {
      this.menuMaxNum = 5;
    }

    let completeHandler = () => {
      this.$container.css('display', 'none');
    };
    for(let i = 0; i < this.menuMaxNum; i++) {

      if(i == this.menuMaxNum - 1) {
        this.$items[i]
          .stop(false)
          .delay(i * 30)
          .animate({'opacity':0, 'margin-right':40}, 300, 'easeOutExpo', completeHandler);
      } else {
        this.$items[i]
          .stop(false)
          .delay(i * 30)
          .animate({'opacity':0, 'margin-right':40}, 300, 'easeOutExpo');
      }
    }
  }
}

export default Menu;
