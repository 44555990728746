class PageApp {
  constructor(mainApp) {
    this.mainApp = mainApp;
  }
  onLoad() {

  }
  destroy() {

  }
  onWindowResize() {
  }

  onWindowScroll() {
  }
}

export default PageApp;
