/*globals $ */

// import slick from 'slick-carousel';

class SlickSlider {
  constructor(app) {
    this.app = app;

    this.carousels = [];


    // this.init();


    this.prevViewMode = 'pc';

    /*
    $(window).on('resize', () => {
      this.onWindowResize();
    });
    this.onWindowResize();
    */
  }

  destroy() {
    if(this.carousels.length > 0) {
      for(let i = 0; i < this.carousels.length; i++) {
        this.carousels[i].slick('unslick');
      }
      this.carousels = [];
    }

  }

  init() {
    $('.slick-slider').each((i, e) => {
      let _carousel   = $(e);
      let _options      = {};

      if(_carousel.attr('data-slick') == 'basic' || _carousel.attr('data-slick') == 'primary') {
        _options = {
          autoplay         : false,
          autoplaySpeed    : 3000,
          arrows           : false,
          slidesToShow     : 1,
          slidesToScroll   : 1,
          dots: true,
          infinite: false,
          pauseOnHover: false,
          /*
          responsive: [
            {
              breakpoint: 1130,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]*/

        };


      }


      /*
      if(
        (_carousel.attr('data-slick') == 'area-detail-rooms' && this.app.viewMode == 'sp')
        || _carousel.attr('data-slick') != 'area-detail-rooms'
      ) {
        _carousel.slick(_options);

        this.carousels.push(_carousel);
      }
      */
      _carousel.slick(_options);
      this.carousels.push(_carousel);

      /*
      if(_carousel.attr('data-slick') == 'single') {
        $('.ImageViewerNav__item').on('click', (e) => {
          _carousel.slick('slickGoTo', parseInt($(e.currentTarget).attr('data-index')));
        });

        _carousel.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
          $('.ImageViewerNav__item').each((i, e) => {
            if($(e).attr('data-index') == String(nextSlide)) {
              $(e).addClass('current')
            } else {
              $(e).removeClass('current')
            }
          });

          $('.ImageViewer__caption p').each((i, e) => {
            if($(e).attr('data-index') == String(nextSlide)) {
              $(e).addClass('current')
            } else {
              $(e).removeClass('current')
            }
          });

        });
      }*/

    });

    // this.app.imgLazyLoad.init();
    // this.init();
  }

  set() {
    if(this.carousels.length > 0) {
      for(let i = 0; i < this.carousels.length; i++) {
        this.carousels[i].slick('unslick');
        this.carousels[i].addClass('slick-slider');
        this.carousels[i].find('.ima-lazy').each((i, e) => {
          $(e).removeClass('set-lazy');
        });
      }
      this.carousels = [];
      this.init();
    } else {
      this.init();
    }
  }

  onWindowResize() {

    if(this.prevViewMode != this.app.viewMode) {

      if(this.carousels.length > 0) {
        for(let i = 0; i < this.carousels.length; i++) {
          this.carousels[i].slick('unslick');
          this.carousels[i].addClass('slick-slider');
          this.carousels[i].find('.ima-lazy').each((i, e) => {
            $(e).removeClass('set-lazy');
          });
        }
        this.carousels = [];
        this.init();
      } else {
        this.init();
      }

      this.prevViewMode = this.app.viewMode;
    }
    /*
    $('.slick-slider[data-slick="recommend"]').each((i, e) => {
      let _gridWidth = $(e).find('.slick-slide').width() - 20;
      $(e).find('button').css('top', Math.floor(_gridWidth / 1.53 / 2));
    });
    */
  }
}

export default SlickSlider;
