/*globals $, YT */

/*class CommonPlayer {
  constructor() {
    this.mode = 'youtube';
    this.player = undefined;
  }

  play() {
    if(this.mode == 'youtube') this.player.playVideo();
    else if(this.mode == 'vimeo') this.player.play();
  }
  pause() {
    if(this.mode == 'youtube') this.player.pauseVideo();
    else if(this.mode == 'vimeo') this.player.pause();
  }
  mute() {
    if(this.mode == 'youtube') this.player.mute();
    else if(this.mode == 'vimeo') this.player.setVolume(0);
  }
  unmute() {
    if(this.mode == 'youtube') this.player.unMute();
    else if(this.mode == 'vimeo') this.player.setVolume(1);
  }
}*/

class YouTubePlayer {
  constructor(mainApp) {
    this.mainApp = mainApp;
    this.currentBackgroundId = '';
    this.isPlayingBackground = false;
    this.isPlayingHalf = false;

    this.isBackgroundFullscreen = false;
    this.isHalfFullscreen = false;

    this.mode = 'youtube';


    let releaseBackgroundFullscreen = () => {
      $(window).off('keydown');
      // this.backgroundPlayer.mute();
      // this.backgroundPlayer.setVolume(0);

      if(this.mode == 'youtube') {
        this.backgroundPlayer.mute();
      } else if(this.mode == 'vimeo') {
        this.backgroundPlayer.setVolume(0);
      }
      this.isBackgroundFullscreen = false;
      $('.BackgroundPlayer').removeClass('BackgroundPlayer--full');
      this.mainApp.pageApp.messageSlider.changeViewMode('default');
    };


    $(document).on('click', '.js-background-full', () => {
      if(this.isBackgroundFullscreen) {
        releaseBackgroundFullscreen();

      } else {
        if(this.isPlayingHalf) {
          this.stopHalf();
        }
        $('.BtnChangeViewmode--half').removeClass('BtnChangeViewmode--current');
        this.isBackgroundFullscreen = true;
        $('.PrimaryPrayerPadding').removeClass('PrimaryPrayerPadding--expand');

        $('.BackgroundPlayer').removeClass('BackgroundPlayer--half');
        $('.BackgroundPlayer').addClass('BackgroundPlayer--full');
        // this.backgroundPlayer.unMute();
        // this.backgroundPlayer.setVolume(1);
        this.mainApp.pageApp.messageSlider.changeViewMode('full');

        if(this.mode == 'youtube') {
          this.backgroundPlayer.unMute();
        } else if(this.mode == 'vimeo') {
          this.backgroundPlayer.setVolume(1);
        }

        $(window).on('keydown', (e) => {
          if(this.isBackgroundFullscreen) {
            if(e.keyCode == 27) {
              releaseBackgroundFullscreen();
            }
          }
        });
      }
    });

    $(document).on('click', '.js-background-half', () => {
      this.playHalfBackground();
    });

    $('.js-close-background-mini').on('click', () => {
      $('.PrimaryPrayerPadding').removeClass('PrimaryPrayerPadding--expand');
      $('.BackgroundPlayer').removeClass('BackgroundPlayer--half');
      // this.backgroundPlayer.mute();
      // this.backgroundPlayer.setVolume(0);

      if(this.mode == 'youtube') {
        this.backgroundPlayer.mute();
      } else if(this.mode == 'vimeo') {
        this.backgroundPlayer.setVolume(0);
      }
      $('.BtnChangeViewmode--half').removeClass('BtnChangeViewmode--current');
    });

    $('.BtnSpCloseFullscreen').on('click', () => {
      /*$(window).off('keydown');
      this.isBackgroundFullscreen = false;
      $('.BackgroundPlayer').removeClass('BackgroundPlayer--full');*/
      releaseBackgroundFullscreen();
    });
    /*
    $('.js-background-full').on('click', () => {
      if(this.isPlayingBackground) {

        if($('html').hasClass('ua-ios')) {
          $('.MobilePlayer').css({
            'display': 'block',
            'opacity': 0
          });

          this.backgroundPlayer.stopVideo();

          this.hiddenPlayer.playVideo();
          let $2 = document.querySelector.bind(document);

          let iframe = $2('#MobilePlayer__embed');
          let requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen || iframe.webkitEnterFullscreen;
          if (requestFullScreen) {
            requestFullScreen.bind(iframe)();
          }


        } else {
          this.backgroundPlayer.playVideo();
          let $2 = document.querySelector.bind(document);

          let iframe = $2('#BackgroundPlayer__embed');
          let requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen || iframe.webkitEnterFullscreen;
          if (requestFullScreen) {
            requestFullScreen.bind(iframe)();
          }
        }
      }
    });
    */



    $(document).on('click', '.js-play-movie-half', (e) => {
      this.playHalf($(e.currentTarget).attr('data-youtube-id'));
    });

    $('.js-halflpayer-full').on('click', () => {
      this.isHalfFullscreen = true;
      $('.HalfPlayer').addClass('HalfPlayer--full');

      $(window).on('keydown', (e) => {
        if(this.isHalfFullscreen) {
          if(e.keyCode == 27) {
            this.isHalfFullscreen = false;
            $('.HalfPlayer').removeClass('HalfPlayer--full');
          }
        }
      });


    });

    $('.js-close-halflpayer').on('click', () => {
      if($('.HalfPlayer').hasClass('HalfPlayer--full')) {
        this.isHalfFullscreen = false;
        $('.HalfPlayer').removeClass('HalfPlayer--full');
      } else {
        this.stopHalf();
      }
    });

  }

  playHalfBackground() {
    if(this.isPlayingHalf) {
      this.halfPlayer.pauseVideo();

      this.isPlayingHalf = false;
      $('.HalfPlayer').stop(false).css({
        'opacity': 0,
        'display': 'none'
      });
      this.halfPlayer.destroy();
      this.mainApp.pageApp.messageSlider.changeViewMode('default');

      if(this.isPlayingBackground) {
        if(this.backgroundPlayer != undefined) {
          $('.BackgroundPlayer').addClass('BackgroundPlayer--show');
          if(this.mode == 'youtube') {
            this.backgroundPlayer.playVideo();
          } else if(this.mode == 'vimeo') {
            this.backgroundPlayer.play();
          }
        }
      }
    }

    $('.BtnChangeViewmode--half').addClass('BtnChangeViewmode--current');
    $('.PrimaryPrayerPadding').addClass('PrimaryPrayerPadding--expand');

    $('.BackgroundPlayer').addClass('BackgroundPlayer--half');
    // this.backgroundPlayer.unMute();
    // this.backgroundPlayer.setVolume(1);

    if(this.mode == 'youtube') {
      this.backgroundPlayer.unMute();
    } else if(this.mode == 'vimeo') {
      this.backgroundPlayer.setVolume(1);
    }
  }

  playHalf(id) {
    if(this.isPlayingBackground) {
      this.pauseBackground();
    }

    $('.BackgroundPlayer').removeClass('BackgroundPlayer--half');
    $('.BtnChangeViewmode--half').removeClass('BtnChangeViewmode--current');

    if(this.backgroundPlayer) {
      // this.backgroundPlayer.mute();
      // this.backgroundPlayer.setVolume(0);

      if(this.mode == 'youtube') {
        this.backgroundPlayer.mute();
      } else if(this.mode == 'vimeo') {
        this.backgroundPlayer.setVolume(0);
      }
    }

    // let html = '<iframe id="ytplayer" type="text/html" width="640" height="360" src="https://www.youtube.com/embed/' + id + '?autoplay=1&modestbranding=1&fs=0&mute=1" frameborder="0"></iframe>';

    if(!this.isPlayingHalf) {
      this.isPlayingHalf = true;
      $('.HalfPlayer').stop(false).css({'opacity':0, 'display':'block'});

      this.halfPlayer = new YT.Player('HalfPlayer__embed', {
        height    : '360',
        width     : '640',
        videoId   : id,
        playerVars: {
          disablekb       : 1,
          modestbranding  : 1,
          rel             : 0,
          playsinline     : 1,
          fs              : 0
        },
        events: {
          'onReady': (event) => {

            /*if($('html').hasClass('ua-sp')) {
              event.target.mute();
            }*/
            event.target.playVideo();
          },
          'onStateChange': () => {
          }
        }
      });

      $('.HalfPlayer').animate({'opacity':1}, 300, 'easeOutExpo');
    } else {
      this.halfPlayer.pauseVideo();
      this.halfPlayer.destroy();

      this.halfPlayer = new YT.Player('HalfPlayer__embed', {
        height    : '360',
        width     : '640',
        videoId   : id,
        playerVars: {
          disablekb       : 1,
          modestbranding  : 1,
          rel             : 0,
          playsinline     : 1,
          fs              : 0
        },
        events: {
          'onReady': (event) => {
            if($('html').hasClass('ua-sp')) {
              event.target.mute();
            }
            event.target.playVideo();
          },
          'onStateChange': () => {
          }
        }
      });
    }

    $('.PrimaryPrayerPadding').addClass('PrimaryPrayerPadding--expand');
  }

  stopHalf(isPageTransition = false) {
    if(this.isPlayingBackground) {
      this.resumeBackground();
    }

    if(this.isPlayingHalf) {
      this.halfPlayer.pauseVideo();

      this.isPlayingHalf = false;
      $('.HalfPlayer').stop(false).animate({'opacity':0}, 300, 'easeOutExpo', () => {
        $('.HalfPlayer').css('display', 'none');
        this.halfPlayer.destroy();
      });
      if(!isPageTransition) {
        $('.PrimaryPrayerPadding').removeClass('PrimaryPrayerPadding--expand');
      }
    }

  }

  pauseBackground() {
    if(this.isPlayingBackground) {
      if(this.backgroundPlayer != undefined) {
        $('.BackgroundPlayer').removeClass('BackgroundPlayer--show');
        this.mainApp.pageApp.messageSlider.changeViewMode('default');
        setTimeout(() => {
          // this.backgroundPlayer.pause();
          if(this.mode == 'youtube') {
            this.backgroundPlayer.pauseVideo();
          } else if(this.mode == 'vimeo') {
            this.backgroundPlayer.pause();
          }
        }, 300);

      }
    }
  }

  pauseBackgroundYT() {
    if(this.isPlayingBackground) {
      if(this.backgroundPlayer != undefined) {
        $('.BackgroundPlayer').removeClass('BackgroundPlayer--show');
        setTimeout(() => {
          this.backgroundPlayer.pauseVideo();
        }, 300);

      }
    }
  }

  resumeBackground() {
    if(this.isPlayingBackground) {
      if(this.backgroundPlayer != undefined) {
        $('.BackgroundPlayer').removeClass('BackgroundPlayer--half');
        $('.BackgroundPlayer').addClass('BackgroundPlayer--show');
        // this.backgroundPlayer.play();
        if(this.mode == 'youtube') {
          this.backgroundPlayer.playVideo();
        } else if(this.mode == 'vimeo') {
          this.backgroundPlayer.play();
        }

      }
    }
  }

  resumeBackgroundYT() {
    if(this.isPlayingBackground) {
      if(this.backgroundPlayer != undefined) {
        $('.BackgroundPlayer').removeClass('BackgroundPlayer--half');
        $('.BackgroundPlayer').addClass('BackgroundPlayer--show');
        this.backgroundPlayer.playVideo();

      }
    }
  }

  playBackground(id) {
    this.playBackgroundYT(id);
    /*if(id != this.currentBackgroundId) {
      this.currentBackgroundId = id;
    }*/
  }

  playBackgroundVimeo(id) {
    this.mode = 'vimeo';
    $('.BackgroundPlayer').addClass('BackgroundPlayer--show');
    this.isPlayingBackground = true;

    var options = {
        id        : id,//273090410, // VimeoのID
        autoplay  : true,
        byline    : false,
        portrait  : false,
        color     : 'ff0000',
        title     : false
    };

    this.backgroundPlayer = new Vimeo.Player($('#BackgroundPlayer__embed'), options);

    this.backgroundPlayer.setVolume(0);
    this.backgroundPlayer.play();
  }


  playBackgroundYT(id) {
    if(id != this.currentBackgroundId) {
      this.mode = 'youtube';
      this.currentBackgroundId = id;
      this.backgroundPlayer = new YT.Player('BackgroundPlayer__embed', {
        height    : '360',
        width     : '640',
        videoId   : this.currentBackgroundId,
        playerVars: {
          disablekb       : 1,
          modestbranding  : 1,
          rel             : 0,
          playsinline     : 1,
          fs              : 0
        },
        events: {
          'onReady': (event) => {
            /*if($('html').hasClass('ua-sp')) {
              event.target.mute();
            }*/
            // PC/SP共に最初はミュートにする
            event.target.mute();
            event.target.playVideo();
            $('.BackgroundPlayer').addClass('BackgroundPlayer--show');
            this.isPlayingBackground = true;
          },
          'onStateChange': (event) => {
            console.log('onStateChange', event);
            if(event.data == 1) {
              $('.BackgroundPlayer').addClass('BackgroundPlayer--show');
            }
          }
        }
      });

      /*
      this.hiddenPlayer = new YT.Player('MobilePlayer__embed', {
        height: '360',
        width: '640',
        videoId: this.currentBackgroundId,
        playerVars: {
          disablekb       : 1,
          modestbranding  : 1,
          rel             : 0,
        },
        events: {
          'onReady': (event) => {
            event.target.mute();
          },
          'onStateChange': (event) => {
          }
        }
      });
      */







    } else {
      if(this.backgroundPlayer != undefined) {
        if(this.mode == 'youtube') {
          this.backgroundPlayer.mute();
          this.backgroundPlayer.playVideo();
        } else if(this.mode == 'vimeo') {
          this.backgroundPlayer.setVolume(0);
          this.backgroundPlayer.play();
        }
      }

      this.isPlayingBackground = true;
    }

  }
  stopBackground() {

    this.isBackgroundFullscreen = false;
    this.isPlayingBackground = false;

    if(this.backgroundPlayer != undefined) {
      // this.backgroundPlayer.pause();
      if(this.mode == 'youtube') {
        this.backgroundPlayer.pauseVideo();
      } else if(this.mode == 'vimeo') {
        this.backgroundPlayer.pause();
      }
    }
  }
  stopBackgroundYT() {

    this.isBackgroundFullscreen = false;
    this.isPlayingBackground = false;

    if(this.backgroundPlayer != undefined) {
      this.backgroundPlayer.stopVideo();
    }
  }
}

export default YouTubePlayer;
