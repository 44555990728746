/*globals $ */
// import jQuery from 'jquery';
// const $ = jQuery;


class ImgLazyLoad {
  constructor() {
    this.offsetTop = -200;

    /*
    if(mainApp.viewMode == 'sp') {
      this.offsetTop = -100;
    }
    */

    if($('html').hasClass('ua-sp')) {
      this.offsetTop = -100;
    }
    this.elements = [];
    // this.init();
  }

  set() {
    let $lazy = $('.img-lazy:not(.set-lazy)');

    $lazy.each((i, el) => {
      let $el = $(el);
      this.elements.push($el);
      el.loaded = false;
      let src = $el.attr('data-original');
      // #「appeer」eventをセットした要素には「set-lazy」クラスを付与
      $el.addClass('set-lazy');
      $el.on('appeer',() => {

        if(!el.loaded){
          $('<img>')
            .one('load',() => {
              if($el.prop('tagName') != 'IMG') {
                $el.css('background-image', 'url("' + src + '")');
              } else {
                $el.attr('src', src);
              }

              el.loaded = true;
              $el.addClass('loaded');
            })
            .attr('src', src);
        }
      });
    });
  }

  destroy() {
    if(this.elements.length) {
      for(let i = 0; i < this.elements.length; i++) {
        this.elements[i].off('appeer');
      }
    }
    this.elements = [];
  }


  initOld(){
    this.elements = [];
    $('.img-lazy').each((i, e) => {
      if($(e).hasClass('set-lazy') && !$(e).hasClass('loaded')) {
        $(e).removeClass('set-lazy')
      }
    });
    // #imgでdata-originalの属性があり、「set-lazy」クラスの付いていない要素を対象に設定
    let $lazy = $('.img-lazy:not(.set-lazy)');
    $lazy.each((i, el) => {
      let $el = $(el);
      this.elements.push($el);
      el.loaded = false;
      let src = $el.attr('data-original');
      // #「appeer」eventをセットした要素には「set-lazy」クラスを付与
      $el.addClass('set-lazy');
      $el.one('appeer',() => {
        if(!el.loaded){
          $('<img>')
            .one('load',() => {
              if($el.prop('tagName') != 'IMG') {
                $el.css('background-image', 'url("' + src + '")');
              } else {
                $el.attr('src', src);
              }

              el.loaded = true;
              $el.addClass('loaded');
            })
            .attr('src', src);
        }
      });
    });
  }


  update(scroll, windowH){

    $.each(this.elements, (i, el) => {
      if($(el).hasClass('loaded')) return;

      if(scroll > $(el).offset().top - windowH - this.offsetTop){
        // $(el).trigger('appeer');

        if(!el.loaded){
          let src = $(el).attr('data-original');
          // console.log('appeerがトリガーされました');
          $('<img>')
            .one('load',() => {
              if($(el).prop('tagName') != 'IMG') {
                $(el).css('background-image', 'url("' + src + '")');
              } else {
                $(el).attr('src', src);
              }

              el.loaded = true;
              $(el).addClass('loaded');
            })
            .attr('src', src);
        }

      }
    });

  }

}
export default ImgLazyLoad;
