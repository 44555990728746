
// import "../scss/style.scss";

// var Promise = require('es6-promise').Promise;

import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import jQuery from 'jquery';
const $ = jQuery;

window.jQuery = $;
window.$ = $;

import 'cssuseragent';
import 'jquery.easing';
import 'slick-carousel';
import PerfectScrollbar from 'perfect-scrollbar';
import barba from '@barba/core';
import barbaCss from '@barba/css';

import SlickSlider from './module/SlickSlider';
// import socialButton from './module/Social';
import ImgLazyLoad from './module/img-lazy-load';
import CounterManager from './module/CounterManager';
import Menu from './module/Menu';
import OnairInfo from './module/OnairInfo';
import YouTubeAPI from './module/YouTubeAPI';
import YouTubePlayer from './module/YouTubePlayer';
import PageApp from './module/PageApp';


class Accordion {
  constructor($container) {
    this.$container = $container;
    this.$btn = this.$container.find('.AccordionHead');
    this.isOpen = false;

    this.$btn.on('click', () => {
      if(this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    });
  }

  open() {
    if(!this.isOpen) {
      this.$container.addClass('Accordion--open');
      this.isOpen = true;
      let targetHeight = this.$container.find('.AccordionContent').outerHeight();
      this.$container.find('.AccordionBody').stop(false).animate({'height': targetHeight}, 1000, 'easeInOutExpo', () => {
        this.$container.find('.AccordionBody').css({
          'height': 'auto',
          'overflow': 'visible'
        });
      });
    }
  }

  close() {
    if(this.isOpen) {
      this.$container.removeClass('Accordion--open');
      this.isOpen = false;
      let targetHeight = this.$container.find('.AccordionContent').outerHeight();
      this.$container.find('.AccordionBody').stop(false).css({
        'height': targetHeight,
        'overflow': 'hidden'
      });
      this.$container.find('.AccordionBody').delay(10).animate({'height': 0}, 400, 'easeOutExpo');
    }
  }
}

class PageAppEventDetail extends PageApp {
  constructor(mainApp) {
    super(mainApp);

    $('.EventContent').find('h3').each((i, e) => {
      $(e).attr('id', 'p' + String(i + 1));
      $(e).attr('data-barba-prevent', 'self');
    });

    $('.EventContent').find('h3').on('click', (e) => {
      e.preventDefault();

      if(location.href.indexOf('#') == -1) {
        location.href = location.href + '#' + $(e.currentTarget).attr('id');
      } else {
        let originalUrl = location.href.slice(0, location.href.indexOf('#'));
        location.href = originalUrl + '#' + $(e.currentTarget).attr('id');
      }
      return false;
    });

    if($('.EventContent').find('h3').length > 1) {
      $('.EventContent').find('h3').each((i, e) => {
        $('.EventAnchorMenu').find('select').append('<option value="' + 'p' + String(i + 1) + '">' + $(e).text() + '</option>');
      });

      let $anchorMenu = $('.EventAnchorMenu').find('select');
      $anchorMenu.change((e) => {
        e.preventDefault();

        if($anchorMenu.val() == '目次') {
          $('html, body').animate({'scrollTop': $('.EventAnchorMenu').offset()['top']}, 400, 'easeOutExpo');
        } else {
          if(location.href.indexOf('#') == -1) {
            location.href = location.href + '#' + $anchorMenu.val();
          } else {
            let originalUrl = location.href.slice(0, location.href.indexOf('#'));
            location.href = originalUrl + '#' + $anchorMenu.val();
          }
        }

        return false;
      });
    } else {
      $('.EventAnchorMenu').remove();
    }

  }
}

class MessageSlider {
  constructor() {
    this.isActiveSlideLink = false;
  }
  init() {
    if($('.MessageLink').hasClass('MessageLink--active')) {
      this.isActiveSlideLink = true;
      this.viewMode = 'default';

      this.$slider = $('.MessageLink__content');
      this.$sliderBg = $('.MessageLinkBg__content');
      this.$text01 = $('.MessageLink__text--01');
      this.$text02 = $('.MessageLink__text--02');
      this.baseWidth = parseInt(this.$text01.width() + 20);
      this.$text02.css('left', this.baseWidth);
      this.posX = parseInt($('.MessageLink').width() + 1);
      // this.posX = window.innerWidth;

      this.$slider.css('transform', 'translate(' + this.posX + 'px, 0)');
      // this.$sliderBg.css('left', '50%');

      this.start();
    }

  }
  changeViewMode(target) {
    // console.log('set view mode', target);
    if(this.isActiveSlideLink) {
      if(target == 'full') {
        this.viewMode = 'full';
        this.posX = window.innerWidth;//parseInt($('.MessageLink').width() + 1);
        $('.MessageLink').removeClass('MessageLink--show');
        $('.MessageLinkBg').addClass('MessageLinkBg--show');
      } else {
        this.viewMode = 'default';
        this.posX = parseInt($('.MessageLink').width() + 1);
        $('.MessageLink').addClass('MessageLink--show');
        $('.MessageLinkBg').removeClass('MessageLinkBg--show');
      }
    }
  }
  start() {
    setTimeout(() => {
      $('.MessageLink').addClass('MessageLink--show');
      // $('.MessageLinkBg').addClass('MessageLinkBg--show');
      this.update = setInterval(() => {
        this.posX -= 3;


        if(this.viewMode == 'full') {
          if(this.posX <= -this.baseWidth) {
            this.posX = 0;
          }
          this.$sliderBg.css('transform', 'translate(' + this.posX + 'px, 0)');

        } else if(this.viewMode == 'default') {
          if(this.posX <= -this.baseWidth) {
            this.posX = 0;
          }
          this.$slider.css('transform', 'translate(' + this.posX + 'px, 0)');
        }


      }, 1000 / 30);
    }, 1000);

  }
  stop() {
    clearInterval(this.update);
  }
}

class PageAppHome extends PageApp {
  constructor(mainApp) {
    super(mainApp);

    this.messageSlider = new MessageSlider();

    this.vhHalf = Math.floor(window.innerHeight / 2);

    if(this.mainApp.isActiveYoutube && this.mainApp.hasTodayEvent) {
      this.loadWaitInterval = setInterval(() => {
        if(this.mainApp.isCheckedOnairStatus) {
          clearInterval(this.loadWaitInterval);

          if(this.mainApp.youtubeAPI.isOnair && $('.HomePrimaryContent--onair').length) {
            $('.HomePrimaryContent--default').remove();
            $('.BackgroundPlayer').css('display', 'block');
            // $('.BackgroundPlayer').addClass('BackgroundPlayer--show');
            // console.log('配信データのチェック↓');
            // console.log(this.mainApp.youtubeAPI.onairItems);
            // console.log('再生する動画のIDは', this.mainApp.youtubeAPI.onairItems[0]['id']['videoId']);

            if($('body').attr('data-onair-id-vimeo') != undefined && String($('body').attr('data-onair-id-vimeo')).length > 1) {
              this.mainApp.youtubePlayer.playBackgroundVimeo(this.mainApp.youtubeAPI.onairItems[0]['id']['videoId']);
            } else {
              this.mainApp.youtubePlayer.playBackground(this.mainApp.youtubeAPI.onairItems[0]['id']['videoId']);
            }

            this.mainApp.onWindowResize();
            this.messageSlider.init();


            $('.BackgroundPlayerUI').find('.js-play-movie-half').attr('data-youtube-id', this.mainApp.youtubeAPI.onairItems[0]['id']['videoId']);

            // console.log('配信中');
          } else {
            $('.HomePrimaryContent--onair').remove();
            // console.log('配信停止中');
          }
          $('.HomePrimaryContent').css('display', 'block');

          if(location.hash != '') {
            if(location.hash == '#about') {
              $('html, body').scrollTop($('.About').offset()['top']);
            } else if(location.hash == '#access') {
              $('html, body').scrollTop($('.Footer').offset()['top']);
            }
          }

          this.mainApp.subAppInit();
          $('.RefreshContainer').removeClass('--next');
          $('.Footer').addClass('-show');
          $('.RBFooter').addClass('-show');

        } else if(this.mainApp.isActiveYoutube == false) {
          clearInterval(this.loadWaitInterval);
          console.log('Stopped by API Error');
          this.startOffair();
        }
      }, 1000 / 30);
    } else {
      this.startOffair();

      /*$('.HomePrimaryContent--onair').remove();
      $('.HomePrimaryContent').css('display', 'block');

      if(location.hash != '') {
        if(location.hash == '#about') {
          $('html, body').scrollTop($('.About').offset()['top']);
        } else if(location.hash == '#access') {
          $('html, body').scrollTop($('.Footer').offset()['top']);
        }
      }

      this.mainApp.subAppInit();
      $('.RefreshContainer').removeClass('--next');
      $('.Footer').addClass('-show');
      $('.RBFooter').addClass('-show');*/
    }
  }

  startOffair() {
    $('.HomePrimaryContent--onair').remove();
    $('.HomePrimaryContent').css('display', 'block');

    if(location.hash != '') {
      if(location.hash == '#about') {
        $('html, body').scrollTop($('.About').offset()['top']);
      } else if(location.hash == '#access') {
        $('html, body').scrollTop($('.Footer').offset()['top']);
      }
    }

    this.mainApp.subAppInit();
    $('.RefreshContainer').removeClass('--next');
    $('.Footer').addClass('-show');
    $('.RBFooter').addClass('-show');
  }

  destroy() {
    if(this.mainApp.isActiveYoutube) {
      this.mainApp.youtubePlayer.stopBackground();
    }
    this.messageSlider.stop();

    $('.BackgroundPlayer').css('display', 'none');
    $('.BackgroundPlayer').removeClass('BackgroundPlayer--show');
  }

  onWindowResize() {
    this.vhHalf = Math.floor(window.innerHeight / 2);

  }

  onWindowScroll(scrollTop) {

    if(this.mainApp.viewMode == 'sp') {
      if(scrollTop + this.vhHalf > $('.Footer').offset()['top']) {
        $('.MenuListItem--about').removeClass('MenuListItem--current');
        $('.MenuListItem--top').removeClass('MenuListItem--current');
        $('.MenuListItem--access').addClass('MenuListItem--current');
      } else if(scrollTop + this.vhHalf > $('.About').offset()['top']) {
        $('.MenuListItem--about').addClass('MenuListItem--current');
        $('.MenuListItem--top').removeClass('MenuListItem--current');
        $('.MenuListItem--access').removeClass('MenuListItem--current');
      } else if(scrollTop > 50) {
        $('.MenuListItem--about').removeClass('MenuListItem--current');
        $('.MenuListItem--top').removeClass('MenuListItem--current');
        $('.MenuListItem--access').removeClass('MenuListItem--current');
      } else {
        $('.MenuListItem--about').removeClass('MenuListItem--current');
        $('.MenuListItem--top').addClass('MenuListItem--current');
        $('.MenuListItem--access').removeClass('MenuListItem--current');
      }
    } else {
      if(scrollTop + this.vhHalf > $('.About').offset()['top']) {
        $('.MenuListItem--about').addClass('MenuListItem--current');
        $('.MenuListItem--top').removeClass('MenuListItem--current');
        $('.MenuListItem--access').removeClass('MenuListItem--current');
      } else if(scrollTop > 50) {
        $('.MenuListItem--about').removeClass('MenuListItem--current');
        $('.MenuListItem--top').removeClass('MenuListItem--current');
        $('.MenuListItem--access').removeClass('MenuListItem--current');
      } else {
        $('.MenuListItem--about').removeClass('MenuListItem--current');
        $('.MenuListItem--top').addClass('MenuListItem--current');
        $('.MenuListItem--access').removeClass('MenuListItem--current');
      }
    }

  }
}

class MainApp {
  constructor() {

    this.viewMode       = 'pc';

    this.currentNamespace = $('.RefreshContainer').attr('data-barba-namespace');


    this.isActiveYoutube = false;
    this.vhHalf = Math.floor(window.innerHeight / 2);

    this.youtubePlayer = new YouTubePlayer(this);
    this.youtubeAPI = new YouTubeAPI(this);

    this.prevSpInnerHeight = 0;

    // this.$currentMenuItem = undefined;
    if($('.MenuListItem--current').length) {
      this.$currentMenuItem = $('.MenuListItem--current');
    } else {
      this.$currentMenuItem = undefined;//$('.MenuListItem--current');
    }


    if(
      ( $('body').attr('data-youtube') != undefined && $('body').attr('data-youtube') == 'active' )
      && ( $('body').attr('data-onair-id') != undefined && String($('body').attr('data-onair-id')).length > 1 )
    ) {
      this.isActiveYoutube = true;
    }

    this.hasTodayEvent = false;
    if($('.TodayEvent').length) {
      this.hasTodayEvent = true;
    }

    if(this.hasTodayEvent) {
      // console.log('今日開催のイベントがあります');
      let todayEventTitle = $('.TodayEvent__title').text();
      // let todayEventUrl = $('.TodayEvent').attr('data-url');
      $('.OnairInfoSliderContent__text').text(todayEventTitle);
      // $('.OnairInfoSliderContent__text').attr('href', todayEventUrl);


      if(this.isActiveYoutube) {
        // this.youtubeAPI = new YouTubeAPI(this);
        this.youtubeAPI.start();
      }
    } else {
      // console.log('今日開催のイベントはありません');
    }



    this.scrollers = [];
    this.slickSlider = new SlickSlider(this);
    this.imgLazyLoad = new ImgLazyLoad(this);
    this.counterManager = new CounterManager(this);

    this.onairInfo = new OnairInfo(this);

    if(Number($('.check-res').width()) > 900) {
      this.viewMode = 'pc';
    } else {
      this.viewMode = 'sp';
    }
    this.menu = new Menu(this);

    $(document).on('touchstart', 'a, button, .ArchiveListItem', (e) => {
      $(e.currentTarget).attr('data-touch', 'true');
    });
    $(document).on('mouseleave', 'a, button, .ArchiveListItem', (e) => {
      $(e.currentTarget).removeAttr('data-touch');
    });

    $('a').on('click', (e) => {

      let href = $(e.currentTarget).attr('href');

      if(href == '/#access') {
        this.menu.close();
        let target = $('.Footer').offset()['top'];
        $('html, body').animate({'scrollTop': target}, 1000, 'easeInOutExpo');
        return false;
      }

      if($('body').hasClass('home')) {
        let href = $(e.currentTarget).attr('href');
        if(href == '/#about') {
          this.menu.close();
          let target = $('.About').offset()['top'];
          if(!$('html').hasClass('ua-sp') && target > $('.Secondary').outerHeight() - this.windowHeight) {
            target = $('.Secondary').outerHeight() - this.windowHeight;
          }

          $('html, body').animate({'scrollTop': target}, 1000, 'easeInOutExpo');
          return false;
        }
      }

    });

    $('.BtnSpPageTop').on('click', () => {
      $('html, body').animate({'scrollTop': 0}, 1000, 'easeInOutExpo');
    });

    // シェアボタン
    /*
    $('.js-btn-share').on('click', (e) => {
      let shareText = document.title;
      if(!$('body').hasClass('home')) {
        shareText = shareText.replace('NIPPONIA - なつかしくて、あたらしい、日本の暮らしをつくる', 'NIPPONIA');
      }

      socialButton($(e.currentTarget).attr('data-type'), location.href, shareText);
      return false;
    });
    */

    this.$window        = $(window);
    this.scrollTop      = 0;
    this.prevScrollTop  = 0;

    $('.js-open-about').on('click', () => {
      if($('.AboutModal').css('display') == 'none') {
        this.openAbout();
      } else {
        this.closeAbout();
      }
    });
    $('.AboutModal').on('click', () => {
      this.closeAbout();
    });

    $('.BtnToggleMenu').on('click', () => {
      if($('body').hasClass('open-menu')) {
        this.menu.close();
      } else {
        this.menu.open();
      }
    });

    /*
    $('body').on('click', () => {
      if($('body').hasClass('onair')) {
        $('body').removeClass('onair');
      } else {
        $('body').addClass('onair');
      }
    });
    */

    $('.MenuListItem--top a').on('click', (e) => {
      if($('body').hasClass('home')) {
        // console.log('トップをクリックしました');
        $('html, body').animate({'scrollTop': 0}, 1000, 'easeInOutExpo');
        this.menu.close();
        e.preventDefault();
        return false;
      }

    });

    if($('body').hasClass('home')) {
      this.pageApp = new PageAppHome(this);
    } else if($('body').hasClass('single-event')) {
      this.pageApp = new PageAppEventDetail(this);
    }

    this.$window
      .on('resize', ()=>{
        this.onWindowResize();
      })
      .on('scroll', ()=>{
        this.onWindowScroll();
      });
    this.onWindowResize();

    barba.use(barbaCss);
    barba.init({
      debug: false,
      // prevent: ({ el }) => ( $('body').hasClass('single-event') && $(el).attr('href').slice(0, 1) == '#' ),
      transitions: [{
        sync: true,
        beforeOnce: (data) => {
          this.currentNamespace = data.next.namespace;
        },
        once() {},
        afterOnce: (data) => {

          this.checkCurrentMenuItem(data.next.namespace);
          this.onairInfo.changeViewMode(data.next.namespace);
          /*
          if(data.next.namespace == 'home') {
          } else {
          }
          */
        },
        beforeLeave: async (data) => {
          this.menu.close();
          this.youtubePlayer.stopHalf(true);

          if($('.AboutModal').css('display') == 'block') {
            this.closeAbout();
          }

          if($('html').hasClass('ua-sp')) {
            $('.Footer').removeClass('-show');
          }

          $(data.next.container).addClass('--next');
          await new Promise((resolve) => {

            return setTimeout(resolve, 420);
          });
        },
        leave() {
        },
        beforeEnter: (data) => {

          // $('.BackgroundPlayer').css('opacity', 0);
          $(data.current.container).css('display', 'none')
          $('html, body').scrollTop(0);
          this.subAppDestroy();
          if(this.pageApp != undefined) {
            this.pageApp.destroy();
          }
        },
        afterLeave: (data) => {
          $('.MenuListItem').removeClass('MenuListItem--current');
          $('.MenuListItem[data-namespace="' + data.next.namespace + '"]').addClass('MenuListItem--current');

          this.checkCurrentMenuItem(data.next.namespace);

          // console.log('data.next.namespace', data.next.namespace);
          this.currentNamespace = data.next.namespace;

          let html = data.next.html;
          let body_class = html.slice(
            html.indexOf('<body class="') + 13,
            html.indexOf('"', html.indexOf('<body class="') + 13)
          );
          if(this.isActiveYoutube && this.youtubeAPI.isOnair) {
            body_class += ' onair';
          }
          // console.log('body_class', body_class);
          $('body').attr('class', body_class);

          /*
          let logoAnimation = $('.RBFooterLogo__animation');
          if(html.indexOf('<h1') != -1) {
            let newLogoContainer = $('<div class="RBFooterLogo"></div>')
            newLogoContainer.append(logoAnimation);
            $('.RBFooterLogo').replaceWith(newLogoContainer);
          } else {
            let newLogoContainer = $('<h1 class="RBFooterLogo"></h1>')
            newLogoContainer.append(logoAnimation);
            $('.RBFooterLogo').replaceWith(newLogoContainer);
          }
          */
          $('html, body').scrollTop(0);
          this.onairInfo.changeViewMode(data.next.namespace);

          if(data.next.namespace == 'home') {
            this.pageApp = new PageAppHome(this);
          } else if(data.next.namespace == 'event-detail') {
            this.pageApp = new PageAppEventDetail(this);

            this.subAppInit();
            // $('.BackgroundPlayer').css('opacity', 1);
            $(data.next.container).removeClass('--next');
          } else {
            this.subAppInit();
            // $('.BackgroundPlayer').css('opacity', 1);
            $(data.next.container).removeClass('--next');
          }

          if($('html').hasClass('ua-sp')) {
            $('.Footer').addClass('-show');
          }



          // console.log($('.About').offset()['top']);
        },
        enter() {},
        afterEnter: (data) => {
          if(data.next.namespace == 'event-detail') {
            this.replaceYoutubeIframe();
          }
        }
      }]
    });



    this.isCheckedOnairStatus = false;

    // 全ページ共通の動作
    // APIで放送中かどうかチェックする
    if(this.isActiveYoutube && this.hasTodayEvent) {
      this.loadWaitInterval = setInterval(() => {
        if(this.youtubeAPI.onCompleteCheckStatus) {
          clearInterval(this.loadWaitInterval);
          // bodyへのクラス付与はここでやる
          this.isCheckedOnairStatus = true;
          if(this.youtubeAPI.isOnair) {
            $('body').addClass('onair');
            $('.StreamStatusContent--onair').css('display', 'flex');
            this.onairInfo.start();
            this.onWindowResize();
          } else {
            $('.StreamStatusContent--offair').css('display', 'block');
          }

        } else if(this.isActiveYoutube == false) {
          // this.isCheckedOnairStatus = true;
          clearInterval(this.loadWaitInterval);
          $('.StreamStatusContent--offair').css('display', 'block');
          console.log('Stopped by API Error');
        }
      }, 1000 / 30);
    } else {
      // $('.StreamStatusContent--onair').css('display', 'flex');
      $('.StreamStatusContent--offair').css('display', 'block');
    }



    /*
    if($('body').hasClass('home')) {
      if(this.isActiveYoutube) {
        this.loadWaitInterval = setInterval(() => {
          if(this.youtubeAPI.onCompleteCheckStatus) {
            clearInterval(this.loadWaitInterval);

            if(this.youtubeAPI.isOnair && $('.HomePrimaryContent--onair').length) {
              $('.HomePrimaryContent--default').remove();
              $('.BackgroundPlayer').addClass('BackgroundPlayer--show');
              console.log('配信データのチェック↓');
              console.log(this.youtubeAPI.onairItems);
              console.log('再生する動画のIDは', this.youtubeAPI.onairItems[0]['id']['videoId']);
              this.youtubePlayer.playBackground(this.youtubeAPI.onairItems[0]['id']['videoId']);

              console.log('配信中');
            } else {
              $('.HomePrimaryContent--onair').remove();
              console.log('配信停止中');
            }
            $('.HomePrimaryContent').css('display', 'block');
            this.subAppInit();
            $('.RefreshContainer').removeClass('--next');
            $('.Footer').addClass('-show');
            $('.RBFooter').addClass('-show');

          }
        }, 1000 / 30);
      } else {
        $('.HomePrimaryContent--onair').remove();
        $('.HomePrimaryContent').css('display', 'block');
        this.subAppInit();
        $('.RefreshContainer').removeClass('--next');
        $('.Footer').addClass('-show');
        $('.RBFooter').addClass('-show');
      }



    } else {
      this.subAppInit();
      $('.RefreshContainer').removeClass('--next');
      $('.Footer').addClass('-show');
      $('.RBFooter').addClass('-show');
    }
    */
    // new PageAppHome();

    /*if($('body').hasClass('home')) {
      this.pageApp = new PageAppHome(this);
    } else {
      this.subAppInit();
      $('.RefreshContainer').removeClass('--next');
      $('.Footer').addClass('-show');
      $('.RBFooter').addClass('-show');
    }*/

    if(!$('body').hasClass('home')) {
      this.subAppInit();
      $('.RefreshContainer').removeClass('--next');
      $('.Footer').addClass('-show');
      $('.RBFooter').addClass('-show');
    }



    this.replaceYoutubeIframe();

  }

  checkCurrentMenuItem(nextNamespace) {
    if(nextNamespace == 'home') {
      this.$currentMenuItem = $('.MenuListItem--top');
    } else if(nextNamespace == 'event-upcoming') {
      this.$currentMenuItem = $('.MenuListItem--upcoming');
    } else if(nextNamespace == 'event-archive') {
      this.$currentMenuItem = $('.MenuListItem--archive');
    } else if(nextNamespace == 'event-finished') {
      this.$currentMenuItem = $('.MenuListItem--finished');
    } else if(nextNamespace == 'event-detail') {
      this.$currentMenuItem = undefined;//$('.MenuListItem--top');
    }
    // console.log('checkCurrentMenuItem nextNamespace', nextNamespace);
  }

  replaceYoutubeIframe() {
    if($('body').hasClass('single-event')) {
      $('.Secondary').find('iframe').each((i, e) => {
        if($(e).attr('src').indexOf('youtube') != -1) {
          let iframe = $(e);
          let ytContainer = $('<span class="YoutubeEmbedContainer"></span>');
          $(e).replaceWith(ytContainer)
          ytContainer.append(iframe);
        }
      });
    }
  }

  subAppInit() {
    this.slickSlider.set();
    this.imgLazyLoad.set();
    this.counterManager.set();

    if($('.ex-scroll').length) {
      $('.ex-scroll').each((i, e) => {
        this.scrollers.push(
          new PerfectScrollbar($(e)[0], {
            'suppressScrollY': true,
            'wheelPropagation': false
          })
        );
      });
    }

    if($('.js-accordion').length) {
      $('.js-accordion').each((i, e) => {
        new Accordion($(e));
      });
    }

    this.onWindowScroll();
  }

  subAppDestroy() {
    this.slickSlider.destroy();
    this.imgLazyLoad.destroy();
    this.counterManager.destroy();

    if(this.scrollers.length) {
      for(let i = 0; i < this.scrollers.length; i++) {
        this.scrollers[i].destroy();
        this.scrollers[i] = null;
      }
      this.scrollers = [];
    }
  }

  openAbout() {
    $('body').addClass('open-about');
    $('.AboutModal').css('display', 'block');
    setTimeout(() => {
      $('.AboutModal').addClass('AboutModal--fadein');
    }, 10);
  }

  closeAbout() {
    $('body').removeClass('open-about');
    $('.AboutModal').removeClass('AboutModal--fadein');
    setTimeout(() => {
      $('.AboutModal').css('display', 'none');
    }, 400);
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();
    if(this.scrollTop < 0) this.scrollTop = 0;


    if(this.scrollTop != this.prevScrollTop) {
      if(this.scrollTop > this.prevScrollTop) {
        this.scrollDiff = 1;
      } else {
        this.scrollDiff = -1;
      }
    }

    /*if($('body').hasClass('home')) {
      if(this.pageApp) {
        this.pageApp.onWindowScroll(this.scrollTop);
      }
    }*/

    if(this.viewMode == 'sp') {
      // console.log('this.currentNamespace', this.currentNamespace);
      if(this.currentNamespace == 'home') {
        if(this.pageApp) {
          this.pageApp.onWindowScroll(this.scrollTop);
        }
      } else if(this.currentNamespace == 'event-detail') {
        if(this.scrollTop + this.vhHalf > $('.Footer').offset()['top']) {
          $('.MenuListItem').removeClass('MenuListItem--current');
          $('.MenuListItem--access').addClass('MenuListItem--current');
        } else {
          $('.MenuListItem').removeClass('MenuListItem--current');
        }
      } else {
        if(this.scrollTop + this.vhHalf > $('.Footer').offset()['top']) {
          $('.MenuListItem').removeClass('MenuListItem--current');
          $('.MenuListItem--access').addClass('MenuListItem--current');
        } else {
          $('.MenuListItem').removeClass('MenuListItem--current');
          this.$currentMenuItem.addClass('MenuListItem--current');
          // $('.MenuListItem').removeClass('MenuListItem--current');
        }
      }
    } else if(this.viewMode == 'pc') {
      if(this.currentNamespace == 'home') {
        if(this.pageApp) {
          this.pageApp.onWindowScroll(this.scrollTop);
        }
      }
    }

    /*
    let isShowSticky = true;
    if(this.scrollTop == this.prevScrollTop) {
      if(this.scrollDiff == 1) {
        isShowSticky = false;
      }
    } else if(this.scrollTop > this.prevScrollTop) {
      isShowSticky = false;
    }
    */

    // console.log('this.scrollTop', this.scrollTop);
    // console.log('this.windowHeight', this.windowHeight);
    this.prevScrollTop = this.scrollTop;
    this.imgLazyLoad.update(this.scrollTop, this.windowHeight);

    // console.log($('.About').offset()['top']);

  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();

    this.vhHalf = Math.floor(window.innerHeight / 2);


    if(Number($('.check-res').width()) > 900) {
      this.viewMode = 'pc';
      $('.Primary').css('height', '100vh');
    } else {
      this.viewMode = 'sp';
      if($('body').hasClass('home') && $('body').hasClass('onair')) {

        if(this.prevSpInnerHeight == 0) {
          this.prevSpInnerHeight = window.innerHeight;
        }
        $('.Primary').css('height', this.prevSpInnerHeight);
        $('.BackgroundPlayer').css('height', this.prevSpInnerHeight);
      } else {
        $('.Primary').css('height', 'auto');
        $('.BackgroundPlayer').css('height', '100vh');
      }
    }

    this.onairInfo.onWindowResize();

    if(this.scrollers.length) {
      for(let i = 0; i < this.scrollers.length; i++) {
        this.scrollers[i].update();
      }
    }

    if($('body').hasClass('home')) {
      if(this.pageApp) {
        this.pageApp.onWindowResize();
      }
    }

    clearInterval(this.resizeCompleteInterval);
    this.resizeCompleteInterval = setInterval(() => {
      clearInterval(this.resizeCompleteInterval);
      // this.subAppInit();
      this.slickSlider.onWindowResize();
    }, 100);

    this.onWindowScroll();
  }


  onLoad() {
    // this.onairInfo.start();
  }

}

let mainApp = undefined;



$('document').ready(() => {

  $.ajax({'type':'get','url': window.url_template + '/assets/images/symbol-defs.svg'}).done((data) => {
    let svg = $(data).find('svg');
    $('body').append(svg);
  });


  mainApp = new MainApp();

});

$(window).on('load', () => {
  mainApp.onLoad();
});


